import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUser } from '../../utils/storage';

interface UserState {
  user: User,
  users: User[],
  usersMin: UserMinimal[],
}

const initialState: UserState = {
  user: {
    email: '',
    id: -1,
    name: '',
    roleId: -1,
    token: '',
  },
  users: [],
  usersMin: [],
};

const getInitialState = (): UserState => {
  const user = getUser();
  if (user == null) {
    return initialState;
  }
  return { ...initialState, user };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setUsers: (state, { payload }: PayloadAction<User[]>) => (
      { ...state, users: payload }
    ),
    setUsersMin: (state, { payload }: PayloadAction<UserMinimal[]>) => (
      { ...state, usersMin: payload }
    ),
  },
});
