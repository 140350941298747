import { initializeApp } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './stores';
import "react-circular-progressbar/dist/styles.css";
import "gantt-task-react/dist/index.css";
import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyAHFEwnRRguj699Cb1KzSHZtNmgvjxerI0",
  authDomain: "diatera-sei-bakti.firebaseapp.com",
  projectId: "diatera-sei-bakti",
  storageBucket: "diatera-sei-bakti.appspot.com",
  messagingSenderId: "330446286063",
  appId: "1:330446286063:web:7b8c2762e366117571ff1a",
  measurementId: "G-JNB9T3S215"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
