import { Card, Colors, Divider, H5, Icon, Switch } from '@blueprintjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '../../../stores';
import { setDarkMode } from '../../../stores/ui';
import SidebarArrowButton from '../../elements/SidebarArrowButton';
import SidebarButton from '../../elements/SidebarButton';
import styles from './sidebar.module.css';

interface SidebarProps {
  collapsed?: boolean,
  onCollapse: () => void,
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, onCollapse }) => {
  const dispatch = useTypedDispatch();
  const { dark } = useSelector((s: RootState) => s.ui);
  const { user } = useSelector((s: RootState) => s.user);

  return (
    <Card className={styles.root} elevation={1}>
      <div className={styles.arrow}>
        <SidebarArrowButton
          icon={collapsed ? 'double-chevron-right' : undefined}
          rightIcon={!collapsed ? 'double-chevron-left' : undefined}
          onClick={onCollapse}
          ali={!collapsed ? 'right' : 'right'}
        >
          {!collapsed && 'Navigation'}&nbsp;
        </SidebarArrowButton>

        <Divider style={{ margin: 0 }} />
      </div>
      <div className={styles.menu}>
        <SidebarButton collapse={collapsed} href="#/" icon="dashboard">
          Dashboard
        </SidebarButton>
        <SidebarButton collapse={collapsed} href="#/projects" icon="projects">          
          Projects
        </SidebarButton>
        <SidebarButton collapse={collapsed} href="#/after-sales" icon="chart">          
          After Sales
        </SidebarButton>

        {user.role != null && user.role.canManageUser > 0 && (
          <div style={{
            borderBottom: `1px solid ${dark ? Colors.GRAY1 : Colors.GRAY5}`,
            borderTop: `1px solid ${dark ? Colors.GRAY1 : Colors.GRAY5}`,
            height: 46,
            padding: '10px 20px',
            margin: 0,
          }}>
            <H5 style={{ margin: 0 }}>
              {collapsed && (
                <Icon icon="cog" color={dark ? Colors.GRAY4 : Colors.GRAY2} />
              )}
              {!collapsed && (
                <span style={{ color: dark ? Colors.GRAY4 : Colors.GRAY2, fontWeight: 400 }}>
                  Settings
                </span>
              )}
            </H5>
          </div>
        )}

        {
          user.role != null && user.role.canManageUser > 0 && (
            <SidebarButton collapse={collapsed} href="#/users" icon="people">
              User Management
            </SidebarButton>
          )
        }

        {
          user.role != null && user.role.canManageUser > 0 && (
            <SidebarButton collapse={collapsed} href="#/accounts/charts" icon="bank-account">
              Chart of Accounts
            </SidebarButton>
          )
        }
      </div>

      <div className={styles.footer}>
        <Divider style={{ margin: '0 -16px 16px' }} />

        <Switch
          alignIndicator={!collapsed ? 'right' : undefined}
          checked={dark}
          label={!collapsed ? 'Dark mode' : undefined}
          onChange={() => dispatch(setDarkMode(!dark))}
        />
      </div>
    </Card>
  );
};

export default Sidebar;
