import axios, { AxiosResponse } from 'axios';
import { getBasicHeader, getBearerHeader } from '../utils/services';
import { BASE_URL } from './url';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getSuggest(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/suggest`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, user: UserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/register`;
  const data = {
    email: user.email,
    name: user.name,
    roleId: user.roleId,
    milestonesId: user.milestonesId,
    password: btoa(user.password),
  };
  return axios.post(url, data, { headers: getBearerHeader(token) });
}

function update(token: string, user: UserFormUpdate): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${user.id}`;
  const data = {
    email: user.email,
    name: user.name,
    roleId: user.roleId,
  };
  return axios.put(url, data, { headers: getBearerHeader(token) });
}

function updatePassword(token: string, user: UpdatePasswordUserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/update/password`;
  const data = new URLSearchParams({
    id: user.id,
    new: btoa(user.newpassword),
    confirm: btoa(user.confirmpassword),
  });

  return axios({
    url,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}

function changePassword(token: string, password: ChangePassword): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/password`;
  const data = new URLSearchParams({
    old: btoa(password.oldPassword),
    new: btoa(password.newPassword),
    confirm: btoa(password.confirmPassword),
  });

  return axios({
    url,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}

function remove(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}

function login(email: string, password: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/login`
  const token = window.btoa(`${email}:${password}`);
  return axios.post(url, {}, { headers: getBasicHeader(token) });
}

const UserService = {
  getAll,
  getSuggest,
  insert,
  update,
  updatePassword,
  changePassword,
  remove,
  login,
};

export default UserService;