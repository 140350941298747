import { Alignment, AnchorButton, Colors, Icon, MaybeElement } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';
import { useTypedSelector } from '../../../stores';
import styles from './sidebararrowbutton.module.css';

interface Props {
  href?: string,
  icon?: IconName | MaybeElement,
  rightIcon?: IconName | MaybeElement,
  ali?: Alignment,
  onClick?: () => void,
  children?: any,
}

const SidebarArrowButton: React.FC<Props> = (props : Props) => {
  const { children, href, icon, onClick, rightIcon, ali } = props;

  const { dark } = useTypedSelector(s => s.ui);
  const color = dark ? Colors.GRAY4 : Colors.GRAY2 ;

  return (
    <AnchorButton
      alignText={ali}
      fill
      large
      minimal
      className={styles.btn}
      href={href}
      icon={<Icon color={color} icon={icon} />}
      rightIcon={<Icon color={color} icon={rightIcon} />}
      style={{ color }}
      onClick={onClick}
    >
      {children}
    </AnchorButton>
  );
};

export default SidebarArrowButton;
