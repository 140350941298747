import RoleService from '../../services/roles';
import { AppThunk } from '..';
import { setLoading, setMessage } from '../ui';
import { setRoles } from '.';
import { NavigateFunction } from 'react-router';

export function getRoles(): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await RoleService.getAll(token);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        setMessage({ intent: 'danger', message: data.message });
        return;
      }
      dispatch(setRoles(data.data));
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot Load data Roles' }));
    }
  };
}

export function insertRole(token: string, sites: RoleForm, navigate : NavigateFunction): AppThunk {
  return async (dispatch) => {
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await RoleService.insert(token, sites);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }
      navigate('/users/roles');
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot input data site' }));
    }
  };
}

export function updateRole(
    token: string, 
    id: number,
    sites: RoleForm, 
    navigate : NavigateFunction
): AppThunk {
  return async (dispatch) => {
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await RoleService.update(token, id, sites);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }
      navigate('/users/roles');
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot input data site' }));
    }
  };
}

export function deleteRole(token: string, id:number, navigate : NavigateFunction): AppThunk {
  return async (dispatch) => {
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await RoleService.remove(token, id);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        setMessage({ intent: 'danger', message: data.message });
        return;
      }
      navigate('/users/roles');
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot Load data Site' }));
    }
  };
}