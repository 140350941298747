import React, { useEffect } from 'react';
import { Classes, FocusStyleManager, Toaster } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import AppRoutes from './routes';
import { RootState, useTypedDispatch } from './stores';
import useThemeDetector from './components/hooks/useThemeDetector';
import { setDarkMode } from './stores/ui';

FocusStyleManager.onlyShowFocusOnTabs();

const AppToast = Toaster.create();

const App: React.FC = () => {
  const dispatch = useTypedDispatch();
  const systemTheme = useThemeDetector();
  const { dark, toast } = useSelector((s: RootState) => s.ui);

  useEffect(() => {
    if (toast.message !== '') {
      AppToast.show({ timeout: 3000, ...toast });
    }
  }, [toast]);

  useEffect(() => {
    dispatch(setDarkMode(systemTheme));
  }, [dispatch, systemTheme]);

  return (
    <div className={dark ? Classes.DARK : ''}>
      <AppRoutes />
    </div>
  );
};


export default App;
