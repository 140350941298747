import axios, { AxiosResponse } from 'axios';
import { getBearerHeader } from '../utils/services';
import { BASE_URL } from './url';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/roles`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, roles: RoleForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/roles`;
  return axios.post(url, roles, { headers: getBearerHeader(token) });
}

function update(token: string, id:number, roles: RoleForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/roles/${id}`;
  return axios.put(url, roles, { headers: getBearerHeader(token) });
}

function remove(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/roles/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}

const RoleService = {
  getAll,
  insert,
  update,
  remove,
};

export default RoleService;
