import { NavigateFunction } from 'react-router-dom';
import { Form } from '../../components/forms/LoginForm';
import UserService from '../../services/user';
import { saveUser } from '../../utils/storage';
import { setLoading, setMessage } from '../ui';
import { AppThunk } from '..';
import { setUser, setUsers, setUsersMin } from '.';

export function login(navigate: NavigateFunction, form: Form): AppThunk {
  const { email, password, remember } = form;

  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await UserService.login(email, password);
      if (remember) {
        saveUser(data.data);
      }
      dispatch(setUser(data.data));
      dispatch(setLoading(false));
      navigate('/');
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function registerUser(user: UserForm, navigate : NavigateFunction): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await UserService.insert(token, user);
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }
      dispatch(setLoading(false));
      navigate('/users');
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot register new user' }));
    }
    dispatch(setLoading(false));
  };
}


export function getUsers(): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await UserService.getAll(token);
      if (data.response_code !== 0) {
        dispatch(setMessage({ intent: 'danger', message: data.message }));
        return;
      }
      dispatch(setUsers(data.data));
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot load data users' }));
    }
    dispatch(setLoading(false));
  };
}

export function getUserMinimal(): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const res = await UserService.getSuggest(token);
      if (res.status === 200) {
        dispatch(setUsersMin(res.data.data));
      }
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot update user\'s data' }));
    }
    setLoading(false);
  };
}

export function updateUser(user: UserFormUpdate, navigate : NavigateFunction): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await UserService.update(token, user);
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }
      dispatch(setLoading(false));
      navigate('/users');
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot update user\'s data' }));
    }
    dispatch(setLoading(false));
  };
}

export function updatePassword(form: UpdatePasswordUserForm): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { status } = await UserService.updatePassword(token, form);
      if (status === 200) {
        dispatch(setLoading(false));
      } else {
        dispatch(setMessage({ intent: 'danger', message: 'Cannot update user password.' }));
      }
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot update user password.' }));
    }
    dispatch(setLoading(false));
  };
}

export function deleteUser(id:number, navigate : NavigateFunction): AppThunk {
  return async (dispatch, getState) => {
    const { token } = getState().user.user;
    if (token == null || token === '') {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await UserService.remove(token, id);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        setMessage({ intent: 'danger', message: data.message });
        return;
      }
      navigate('/users');
    } catch (err) {
      dispatch(setMessage({ intent: 'danger', message: 'Cannot delete user' }));
    }
    dispatch(setLoading(false));
  };
}