import { 
  login, 
  getUsers, 
  getUserMinimal,
  updateUser, 
  updatePassword, 
  registerUser, 
  deleteUser 
} from './action';
import { userSlice } from './user';

export const { setUser, setUsers, setUsersMin } = userSlice.actions;
export { login, getUsers, getUserMinimal, updateUser, updatePassword, registerUser, deleteUser };

export default userSlice.reducer;
