import React from 'react';
import NavButton from '../../elements/NavButton';
import styles from './navigation.module.css';

interface Props {
  className?: string,
}

const Navigation: React.FC<Props> = ({ className }) => {
  const classes = [className, styles.nav].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      <NavButton href="#/" icon="dashboard" />
      <NavButton href="#/notification" icon="notifications" />
      <NavButton href="#/profile" icon="user" />
    </div>
  );
};

export default Navigation;
