import { AnchorButton, Icon, IconName, MaybeElement } from '@blueprintjs/core';
import React from 'react';
import { useLocation } from 'react-router';
import styles from './navbutton.module.css';

interface Props {
  href?: string,
  icon: IconName | MaybeElement,
  iconSize?: number,
}

const NavButton: React.FC<Props> = ({ href, icon, iconSize }) => {
  const location = useLocation();
  const active = `/${location.pathname.split('/')[1]}` === href?.substr(1, href.length);

  return (
    <AnchorButton large minimal className={styles.btn} href={href}>
      <Icon
        color={active ? '#137CBD' : '#5C7080'}
        icon={icon}
        size={iconSize == null ? iconSize : 20}
      />
    </AnchorButton>
  );
};

export default NavButton;
