import { Colors, MaybeElement } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../stores';
import { setSidebar } from '../../../stores/ui';
import { saveSidebar } from '../../../utils/storage';
import Header, { HeaderContext } from '../Header';
import Navigation from '../Navigation';
import Sidebar from '../Sidebar';
import styles from './mainbase.module.css';

interface Props {
  children?: React.ReactNode,
}

const MainBase: React.FC<Props> = ({ children }) => {
  const [content, setContent] = useState<MaybeElement>(null);

  const dispatch = useDispatch();
  const { dark, sidebar } = useSelector((s: RootState) => s.ui);

  const isCollapsed = sidebar === 'collapse';
  const className = [isCollapsed ? styles.collapse : styles.expand].filter(Boolean).join(' ');

  const onCollapse = () =>{
    const state = sidebar === 'collapse' ? 'expand' : 'collapse';
    saveSidebar(state);
    dispatch(setSidebar(state));
  }

  return (
    <HeaderContext.Provider value={{ content, setContent }}>
      <Header collapse={isCollapsed} className={styles.header} />
      <Navigation className={styles.mobile} />

      <div className={className}>
        <div className={styles.sidebar}>
          <Sidebar collapsed={isCollapsed} onCollapse={onCollapse} />
        </div>

        <div
          className={styles.content}
          style={{ backgroundColor: dark ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY5 }}
        >
          {children}
        </div>
      </div>
    </HeaderContext.Provider>
  );
};

export default MainBase;
