import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Routes, Route } from "react-router-dom";
import LoadingPage from '../components/layouts/LoadingPage';
import MainBase from '../components/layouts/MainBase';
import { RootState } from '../stores';

const load = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingPage />}>
    <Component {...props} />
  </Suspense>
);

const AfterSales = load(lazy(() => import('../pages/AfterSales')));
const Basts = load(lazy(() => import('../pages/Basts')));
const BastsDetail = load(lazy(() => import('../pages/BastsDetail')));
const BastsModify = load(lazy(() => import('../pages/BastsModify')));
const BastsNew = load(lazy(() => import('../pages/BastsNew')));
const ChartOfAccounts = load(lazy(() => import('../pages/ChartOfAccounts')));
const CorrectiveMaintenance = load(lazy(() => import('../pages/CorrectiveMaintenance')));
const CorrectiveMaintenanceDetail = load(lazy(() => import('../pages/CorrectiveMaintenanceDetail')));
const CorrectiveMaintenanceNew = load(lazy(() => import('../pages/CorrectiveMaintenanceNew')));
const CorrectiveMaintenanceModify = load(lazy(() => import('../pages/CorrectiveMaintenanceModify')));
const Dashboard = load(lazy(() => import('../pages/Dashboard')));
const Login = load(lazy(() => import('../pages/Login')));
const Milestone = load(lazy(() => import('../pages/Milestone')));
const OperationMaintenance = load(lazy(() => import('../pages/OperationMaintenance')));
const OperationMaintenanceDetail = load(lazy(() => import('../pages/OperationMaintenanceDetail')));
const OperationMaintenanceModify = load(lazy(() => import('../pages/OperationMaintenanceModify')));
const OperationMaintenanceNew = load(lazy(() => import('../pages/OperationMaintenanceNew')));
const PreventiveMaintenance = load(lazy(() => import('../pages/PreventiveMaintenance')));
const PreventiveMaintenanceDetail = load(lazy(() => import('../pages/PreventiveMaintenanceDetail')));
const PreventiveMaintenanceModify = load(lazy(() => import('../pages/PreventiveMaintenanceModify')));
const PreventiveMaintenanceNew = load(lazy(() => import('../pages/PreventiveMaintenanceNew')));
const Profile = load(lazy(()=> import('../pages/Profile')));
const Projects = load(lazy(() => import('../pages/Projects')));
const ProjectsNew = load(lazy(() => import('../pages/ProjectsNew')));
const ProjectsModify = load(lazy(() => import('../pages/ProjectsModify')));
const ProjectsDetail = load(lazy(() => import('../pages/ProjectsDetail')));
const ProjectsActivities = load(lazy(() => import('../pages/ProjectsActivities')));
const Sites = load(lazy(() => import('../pages/Sites')));
const Task = load(lazy(() => import('../pages/Task')));
const UserManagement = load(lazy(()=> import('../pages/UserManagement')));
const UserModify = load(lazy(()=> import('../pages/UserModify')));
const UserNew = load(lazy(()=> import('../pages/UserNew')));
const UserRole = load(lazy(()=> import('../pages/UserRole')));
const UserRoleNew = load(lazy(()=> import('../pages/UserRoleNew')));
const UserRoleModify = load(lazy(()=> import('../pages/UserRoleModify')));

const ProtectedRoutes: React.FC<{ user: User }> = ({ user }) => {  
  if (user.id < 0) {
    return <Navigate replace to="/login" />;
  }

  return (
    <MainBase>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/milestones/:milestoneId/tasks/:id" element={<Task />} />
        <Route path="/projects/new" element={<ProjectsNew />} />
        <Route path="/projects/:code" element={<ProjectsDetail />} />
        <Route path="/projects/:code/modify" element={<ProjectsModify />} />
        <Route path="/projects/:code/activities" element={<ProjectsActivities />} />
        <Route path="/projects/:code/sites" element={<Sites />} />
        <Route path="/projects/:code/sites/:siteId/activities/:id" element={<Milestone />} />
        <Route path="/accounts/charts/*" element={<ChartOfAccounts />}/>
        <Route path="/after-sales" element={<AfterSales />}/>
        <Route path="/after-sales/cm" element={<CorrectiveMaintenance />}/>
        <Route path="/after-sales/cm/new" element={<CorrectiveMaintenanceNew />}/>
        <Route path="/after-sales/cm/:id" element={<CorrectiveMaintenanceDetail />}/>
        <Route path="/after-sales/cm/:id/modify" element={<CorrectiveMaintenanceModify />} />
        <Route path="/after-sales/pm" element={<PreventiveMaintenance />}/>
        <Route path="/after-sales/pm/new" element={<PreventiveMaintenanceNew />}/>
        <Route path="/after-sales/pm/:id" element={<PreventiveMaintenanceDetail />}/>
        <Route path="/after-sales/pm/:id/modify" element={<PreventiveMaintenanceModify />}/>
        <Route path="/after-sales/basts" element={<Basts />}/>
        <Route path="/after-sales/basts/new" element={<BastsNew />}/>
        <Route path="/after-sales/basts/:id" element={<BastsDetail />}/>
        <Route path="/after-sales/basts/:id/modify" element={<BastsModify />}/>
        <Route path="/after-sales/om" element={<OperationMaintenance />}/>
        <Route path="/after-sales/om/new" element={<OperationMaintenanceNew />}/>
        <Route path="/after-sales/om/:id" element={<OperationMaintenanceDetail />}/>
        <Route path="/after-sales/om/:id/modify" element={<OperationMaintenanceModify />}/>
        <Route path="/users" element={<UserManagement />} />
        <Route path="/users/new" element={<UserNew />} />;
        <Route path="/users/roles" element={<UserRole />} />
        <Route path="/users/roles/new" element={<UserRoleNew />} />
        <Route path="/users/roles/:id" element={<UserRoleModify />} />
        <Route path="/users/:id" element={<UserModify />} />;
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </MainBase>
  );
};

const AppRoutes: React.FC = () =>  {
  const { user } = useSelector((s: RootState) => s.user);

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<ProtectedRoutes user={user} />} />
    </Routes>
)};

export default AppRoutes;
