import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  roles: Role[],
}

const initialState: State = {
  roles: [],
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, { payload }: PayloadAction<Role[]>) => (
      { ...state, roles: payload }
    ),
  },
});
