import { AnchorButton, Colors, Icon, MaybeElement } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';
import { useLocation } from 'react-router';
import { useTypedSelector } from '../../../stores';
import styles from './sidebarbutton.module.css';

interface Props {
  children?: React.ReactNode,
  collapse?: boolean,
  href: string,
  icon?: IconName | MaybeElement,
  rightIcon?:IconName | MaybeElement,
  fixColor?:string,
  onClick?: () => void,
}

const SidebarButton: React.FC<Props> = (props) => {
  const { collapse, children, href, icon, onClick, rightIcon } = props;
  const location = useLocation();
  const { dark } = useTypedSelector(s => s.ui);

  const currentLocation = '#' + location.pathname.substring(0, href.length - 1);
  const active = href === '#/' ? location.pathname === '/' : currentLocation === href;
  const backgroundColor = active ? Colors.BLUE3 : undefined;
  
  let color = active ? Colors.LIGHT_GRAY5 : undefined;
  if (!active) {
    color = !dark ? Colors.DARK_GRAY3 : Colors.LIGHT_GRAY5;
  }

  return (
    <AnchorButton
      id={active ? "menuListActive" : ""}
      fill
      large
      minimal
      className={styles.btn}
      href={href}
      icon={<Icon color={color} icon={icon} />}
      rightIcon={<Icon color={color} icon={rightIcon} />}
      style={{ color: color, backgroundColor}}
      onClick={onClick}
    >
      {collapse ? ' ' : children}
    </AnchorButton>
  );
};

export default SidebarButton;
